import React, { useState,useEffect } from 'react';
import {  Paper,
  Typography,
  TextField,
  Button,
  Grid,
 } from '@mui/material';
import bill from './component/assets/bill.jpeg';
import logo from './log.png';
import DeleteIcon from '@mui/icons-material/Delete';
import html2pdf from 'html2pdf.js';
import axios from 'axios';
import { Buffer } from 'buffer';

const InvoiceGenerator = () => {
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const fetchInvoiceNumber = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/invoice'); // Add the backend server URL
      console.log('Fetched invoice number:', response.data.invoiceNumber);
      setInvoiceNumber(response.data.invoiceNumber);
    } catch (error) {
      console.error('Error fetching invoice number:', error);
    }
  };
  

  useEffect(() => {
    fetchInvoiceNumber();
  }, []);
 
  const [isLoading, setIsLoading] = useState(false);
    const [clientDetails, setClientDetails] = useState({
      clientName: '',
      clientAddress: '',
      clientDistrict: '',
      clientPostalCode: '',
      clientCountry: '',
      clientAddNo: '',
      clientVatNumber: '',
      clientCRN: '',
      words:'',
    });
  
    const [invoiceDetails, setInvoiceDetails] = useState({
      invoiceDate: '',
      supplyDate: '',
      contractNo: '',
      invoicePeriod: '',
      projectRefNo: '',
    });
    const [products, setProducts] = useState([
      {
      
        unitRate: '',
        quantity: '',
        vatRate: '15.00',
        totalExclVAT: 0,
        vatAmount: 0,
        totalInclVAT: 0,
        translatedNatureOfGoods:'',
        
      },
    ]);
    const [example, setexample] = useState([
      {
        natureOfGoods: '',       // Nature of Goods input
    productDescription: '', 
  },
]);
    const translations = {
      "Nature of Goods": "طبيعة البضائع",
      "Electronics": "إلكترونيات",
      "Clothing": "ملابس",
      "Furniture": "أثاث",
      "Food": "طعام",
      "Manpower":"قوى عاملة",
      "manpower":"قوى عاملة",
      // Add more translations as needed
    };

    const translateText = async (text) => {
      debugger;
      if (typeof text === 'string' && text.trim() !== '') { // Check if text is a string
        console.log(text);
        setIsLoading(true); // Show loader
        try {
          const response = await axios.post('https://translation-python-api.onrender.com/arb', { text: text });

          if (response.status === 200) {
            console.log(response);
            return response.data.translated_text; // Return the translated text
          } else {
            throw new Error(`Failed to translate text. Status code: ${response.status}`);
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setIsLoading(false); // Hide loader
        }
      }
      return text; // If no text or error, return the original text
    };
    // Helper function to calculate the amounts for each product
    const calculateProductAmounts = (unitRate, quantity, vatRate) => {
      const totalExclVAT = (parseFloat(unitRate) || 0) * (parseFloat(quantity) || 0);
      const vatAmount = (totalExclVAT * (parseFloat(vatRate) || 0)) / 100;
      const totalInclVAT = totalExclVAT + vatAmount;
      return {
        totalExclVAT,
        vatAmount,
        totalInclVAT,
      };
    };
    const handleInputChange = (e, section) => {
      const { name, value } = e.target;
      if (section === 'client') {
        setClientDetails({ ...clientDetails, [name]: value });
      } else if (section === 'invoice') {
        setInvoiceDetails({ ...invoiceDetails, [name]: value });
      } };
    // Handle input change for products
    const handleProductChange = async(e, index) => {
      debugger
      const { name, value } = e.target;
      const updatedProducts = [...products];
      
      updatedProducts[index][name] = value;
  
      // Calculate the totals after the input change
      const { totalExclVAT, vatAmount, totalInclVAT } = calculateProductAmounts(
        updatedProducts[index].unitRate,
        updatedProducts[index].quantity,
        updatedProducts[index].vatRate
      );
  
      // Update the product with calculated amounts
      updatedProducts[index].totalExclVAT = totalExclVAT.toFixed(2);
      updatedProducts[index].vatAmount = vatAmount.toFixed(2);
      updatedProducts[index].totalInclVAT = totalInclVAT.toFixed(2);
  // Update the translated text based on the input
 
  setexample((prevProduct) => ({
    ...prevProduct,
    [name]: value,
  }));     // Update the state with the translated value
        setProducts(updatedProducts);
      
    };
  
    // Add a new product row
    const addProductRow = () => {
      setProducts((prevProducts) => [
        ...prevProducts,
        { natureOfGoods: '', unitRate: '', quantity: '', vatRate: '15.00', totalExclVAT: 0, vatAmount: 0, totalInclVAT: 0 },
      ]);
    };
  
    // Remove a product row
    const removeProductRow = (index) => {
      const updatedProducts = products.filter((_, i) => i !== index);
      setProducts(updatedProducts);
    };
  
    // Helper functions to calculate totals for all products
    const calculateTotalExclVAT = () => {
      return products.reduce((acc, product) => acc + parseFloat(product.totalExclVAT || 0), 0).toFixed(2);
    };
  
    const calculateTotalVAT = () => {
      return products.reduce((acc, product) => acc + parseFloat(product.vatAmount || 0), 0).toFixed(2);
    };
  
    const calculateTotalInclVAT = () => {
      return products.reduce((acc, product) => acc + parseFloat(product.totalInclVAT || 0), 0).toFixed(2);
    };
  
    const calculateBalanceDue = () => {
      return calculateTotalInclVAT(); // Assuming balance due is total including VAT
    };

   
  const generateTLV = (tag, value) => {
  const tagHex = tag.toString(16).padStart(2, '0');
  const lengthHex = value.length.toString(16).padStart(2, '0');
  const valueHex = Buffer.from(value).toString('hex');
  return tagHex + lengthHex + valueHex;
};

const getBillDetailsForQRCode = () => {
  const sellerName = clientDetails.clientName || '';
  const vatRegistrationNumber = clientDetails.clientVatNumber || '';
  const invoiceDate = invoiceDetails.invoiceDate || '';

  // Ensure totalInclVAT and vatAmount are numbers
  const totalAmount = products
    .reduce((acc, product) => acc + (parseFloat(product.totalInclVAT) || 0), 0)
    .toFixed(2);
  
  const vatAmount = products
    .reduce((acc, product) => acc + (parseFloat(product.vatAmount) || 0), 0)
    .toFixed(2);

  const tlvEncoded = 
    generateTLV(1, sellerName) +
    generateTLV(2, vatRegistrationNumber) +
    generateTLV(3, invoiceDate) +
    generateTLV(4, totalAmount) +
    generateTLV(5, vatAmount);

  return Buffer.from(tlvEncoded, 'hex').toString('base64');
};

const qrCodeData = getBillDetailsForQRCode();

    
    
    // Transliterate Latin to Arabic
    const transliterateToArabic = (text) => {
      const transliterationMap = {
          'a': 'ا', 'b': 'ب', 'c': 'ك', 'd': 'د', 'e': 'ي', 'f': 'ف',
          'g': 'ج', 'h': 'ه', 'i': 'ي', 'j': 'ج', 'k': 'ك', 'l': 'ل',
          'm': 'م', 'n': 'ن', 'o': 'و', 'p': 'ب', 'q': 'ق', 'r': 'ر',
          's': 'س', 't': 'ت', 'u': 'و', 'v': 'ف', 'w': 'و', 'x': 'كس',
          'y': 'ي', 'z': 'ز',

          'A': 'ا', 'B': 'ب', 'C': 'ك', 'D': 'د', 'E': 'ي', 'F': 'ف',
          'G': 'ج', 'H': 'ه', 'I': 'ي', 'J': 'ج', 'K': 'ك', 'L': 'ل',
          'M': 'م', 'N': 'ن', 'O': 'و', 'P': 'ب', 'Q': 'ق', 'R': 'ر',
          'S': 'س', 'T': 'ت', 'U': 'و', 'V': 'ف', 'W': 'و', 'X': 'كس',
          'Y': 'ي', 'Z': 'ز',

          ' ': ' ', ',': '،', '.': '.',
      };

      return Array.from(text).map(char => transliterationMap[char] || char).join('');
  };
  
    
  const printInvoice = async () => {
    // Translate both fields before printing
 const translatedGoods = await translateText(example.natureOfGoods);
 const translatedDescription = await translateText(example.productDescription);
   const printWindow = window.open('', '', 'height=600,width=800');
   printWindow.document.write(`
    <html>
       <head>
         <title>Vat Invoice</title>
         <style>
     body {
         font-family: Arial, sans-serif;
         margin: 20px;
         padding: 20px;
     }
     table {
         width: 900px;
         border-collapse: collapse;
         margin-top: 20px;
         border: 4px solid black;
         margin-left: auto;
         margin-right: auto;
     }
     th, td {
         border: 2px solid black;
         padding: 1px;
         text-align: left;
         height: 20px; 
         line-height: 1; /* Adjust line height if needed */
     }
     th {
         background-color: #dadada;
     }
     .Invoice {
         text-align: center;
     }
     
     .invoice-title {
         text-align: center;
         font-size: 1.5rem;
         margin-bottom: 10px;
     }
     .bold {
         font-weight: bold;
     }
     .no-border {
         border: none;
     }
     .col1 {
         height: 40px;
         text-align: center;
     }
     .col2 {
         width: 140px; 
     }
     .col3 {
         height: 90px;
         text-align: center;
     }
     .col4 {
         width: 20%; 
     }
     .col5 {
         width: 24%; 
     }
     .col6 {
         width: 7%;
         text-align: center; 
     }
     .col7 {
         width: 23%; 
     }
     .col8 {
         width: 8%; 
     }
     .col9 {
         height: 30px;
     }
     .col10 {
         height: 30px;
         text-align: center;
     }
     .colgoods {
         width: 31%; 
     }
     .colQty {
         width: 4%;
         text-align: center; 
     }
     .collast{
         width: 20%;
     }
     .header-container {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 20px; /* Space between heading and logo */
     }
     .logo-container img {
         max-width: 100px; /* Adjust width as needed */
         height: auto;
     }
     .left-align {
         float: left;
     }
     .right-align {
         float: right;
         text-align: right; /* Ensures text is aligned to the right */
     }
     .clearfix::after {
         content: "";
         display: table;
         clear: both;
     }
     .Headtitle td {
         padding: 4px; 
         background-color: #648ec0;
     }

     .Headtitle h3 {
         margin: 0; 
         padding: 3px; 
         line-height: 1.2; 
         color: white;
     }
     p {
         margin: 0; 
     }
     .signature-section {
         text-align: center;
         vertical-align: bottom;
         padding-bottom: 10px;
     }
     .signature-section td {
         height: 60px;
     }
     .new{
         color: white;
         text-align: center; 
         background-color: #648ec0;
     }
     .qr-section {
         width: 20%;
         text-align: center;
         vertical-align: middle;
         border-left: 2px solid black;
     }
     .text-section {
         width: 30%;
         text-align: center;
         vertical-align: middle;
         border-left: 2px solid black;
     }
     
     .bold {
         font-weight: bold;
     }
     .arabic {
         font-size: 12px;
         display: block;
         font-family: 'Arial', sans-serif;
     }
     .align-end {
     text-align: right; /* Aligns text to the right */
     direction: rtl; /* Sets text direction to RTL */
 }

 @media print {
     * {
         -webkit-print-color-adjust: exact; 
         color-adjust: exact;               
         print-color-adjust: exact;         
     }
     body {
         margin: 0;
         padding: 0;
     }
 }
 </style>
</head>
<body>
 <div class="header-container">
     <div style="text-align: right;">
       <h1 style="color: #648ec0;">مؤسسة محمد سعود بن ظافر اليامي للمقاولات</h1>
       <h2 style="color: #648ec0;">Mohammed saud bin dhafer al yami cont.Est.</h2>
     </div>
     <div class="logo-container">
       <img src=${bill} alt="Logo Image" /> 
   </div>
 </div>
 <div>
     <p class="Invoice"><strong>Vat Invoice:</strong></br><span class="bold">فاتورة ضريبة القيمة المضافة</span></p>
     <div class="clearfix">
         <p class="left-align" style="color: rgb(205, 0, 0);"><strong>Invoice No:</strong> <span class="bold">${invoiceNumber}</span></p>
         <p class="right-align" style="color: rgb(205, 0, 0);"><strong>رقم الفاتورة:</strong> <span class="bold">${invoiceNumber}</span></p>
     </div>
 </div>

 <table class="Detail">
        <tr class="Headtitle">
            <td colspan="8">
                <div class="clearfix">
                <h3 class="left-align"><strong><u>Our Details:</u></strong></h3>
                <h3 class="right-align" "dir="rtl"><strong><u>تفاصيلنا:</u></strong></h3>
                </div>
            </td>
        </tr>
       <tr>
    <th class="col2">Name:</th>
    <td colspan="3" class="col2">Mohammed saud bin dhafer al yami cont. Est.</td>
    <td colspan="3" class="col2" style="text-align: end;">محمد سعود بن ظافر اليامي للمقاولات.</td>
    <th class="col2 align-end">الاسم:</th>
</tr>
<tr>
    <th class="col2">PO Box No:</th>
    <td colspan="3" class="col2">35514</td>
    <td colspan="3" class="col2"  style="text-align: end;">35514</td> 
    <th class="col2 align-end">صندوق البريد:</th>
</tr>
        <tr>
            <th class="col2">Building No:</th>
            <td class="col2">HNAB4999</td>
            <th class="col2">City:</th>
            <td> Makkah mukarramah street</td>
           <td  style="text-align: end;" >شارع مكة المكرمة</td>
            <th class="col2 align-end">المدينة:</th>
            <td style="text-align: end;">HNAB4999</td>
            <th class="col2 align-end">رقم المبنى:</th>
        </tr>
        <tr>
            <th class="col2">Addl. No:</th>
            <td> 8944</td>
            <th class="col2">District:</th>
            <td>Safath jubail</td>
            <td style="text-align: end;">صفاة الجبيل</td>
            <th class="col2 align-end"> الحي:</th>
            <td style="text-align: end;"> 8944</td>
            <th class="col2 align-end">الرقم الإضافي:</th>
        </tr>
        <tr>
            <th class="col2">Postal Code:</th>
            <td>11564</td>
            <th class="col2">Country:</th>
            <td>Kingdom of Saudi Arabia</td>
            <td style="text-align: end; width:10%;">المملكة العربية السعودية</td>
            <th class="col2 align-end"> الدولة:</th>
            <td style="text-align: end;">11564</td>
            <th class="col2 align-end">الرمز البريدي:</th>
        </tr>
        <tr>
            <th class="col2">Vat Number:</th>
            <td>311404508500003</td>
            <th class="col2">CRN:</th>
            
            <td colspan="2" style="text-align:center;">2055145790</td>
            <th class="col2 align-end"> السجل التجاري:</th>
            <td style="text-align: end;">311404508500003</td>
            <th class="col2 align-end">رقم ضريبة القيمة المضافة:</th>
        </tr>
    </table>




 
 <table class="Detail">
     <tr class="Headtitle">
         <td colspan="8" class="col2">
             <div class="clearfix">
             <h3 class="left-align"><strong><u>Client Details:</u></strong></h3>
             <h3 class="right-align" dir="rtl"><strong><u>تفاصيل العميل:</u></strong></h3>
             </div>
         </td>
     </tr>
     <tr>
         <th class="col2">Name:</th>
         <td colspan="3" class="col2">${clientDetails.clientName}</td>
         <td colspan="3" class="col2"  style="text-align: end;"> ${transliterateToArabic(clientDetails.clientName)}</td>
         <th class="col2 align-end">اسم:</th>
     </tr>
     <tr>
         <th class="col9">Address:</th>
         <td colspan="3" class="col9"> ${clientDetails.clientAddress}</td>
         <td colspan="3" class="col9"  style="text-align: end;"> ${transliterateToArabic(clientDetails.clientAddress)}</td> 
         <th class="col9 align-end">عنوان:</th>
     </tr>
    <tr>
         <th class="col2">Addl. No:</th>
         <td>${clientDetails.clientAddNo}</td>
         <th class="col2">District:</th>
         <td style="width : 10%;">${clientDetails.clientDistrict}</td>
         <td  style="text-align: end; width : 10%;">${transliterateToArabic(clientDetails.clientDistrict)}</td>
         <th class="col2 align-end">الحي:</th>
         <td  style="text-align: end;">${clientDetails.clientAddNo}</td>
         <th class="col2 align-end">الرقم الإضافي:</th>
     </tr>
     <tr>
         <th class="col2">Postal Code:</th>
         <td>${clientDetails.clientPostalCode}</td>
         <th class="col2">Country:</th>
         <td> ${clientDetails.clientCountry}</td>
         <td  style="text-align: end;">${transliterateToArabic(clientDetails.clientCountry)}</td>
         <th class="col2 align-end">دولة:</th>
         <td  style="text-align: end;">${transliterateToArabic(clientDetails.clientPostalCode)}</td>
         <th class="col2 align-end">الرمز البريدي:</th>
     </tr>
   <tr>
         <th class="col2">Vat Number:</th>
         <td>${clientDetails.clientVatNumber}</td>
         <th class="col2">CRN:</th>
         <td colspan="2" style="text-align:center;">${clientDetails.clientCRN}</td>
         <th class="col2 align-end">السجل التجاري:</th>
         <td  style="text-align: end;">${clientDetails.clientVatNumber}</td>
         <th class="col2 align-end">رقم ضريبة القيمة المضافة: </th>
     </tr>

 <table class="Detail">
     <tr>
         <th class="col1">  تاريخ الفاتورة <br>Invoice Date</th>
         
         <th class="col1">تاريخ التوريد <br>Supply Date</th>
         
         <th class="col1">   رقم العقد / أمر الشراء  <br>Contract/PO No</th>
        
         <th class="col1">  فترة الفاتورة <br>Invoice Period</th>
        
         <th class="col1"> رقم المشروع / المرجع  <br>Project/Reference No</th>
        
     </tr>
     <tr>
         <td class="col2" style="text-align: center;">${invoiceDetails.invoiceDate}</td>
         <td class="col2" style="text-align: center;">${invoiceDetails.supplyDate}</td>
         <td class="col2" style="text-align: center;">${invoiceDetails.contractNo}</td>
         <td class="col2" style="text-align: center;">${invoiceDetails.invoicePeriod}</td>
         <td class="col2" style="text-align: center;">${invoiceDetails.projectRefNo}</td>
     </tr>
 </table>

 <table class="Detail">
<thead>
 <tr>
   <th class="col3"> الإجمالي شامل الضريبة (ريال سعودي) <br>Total Incl. VAT (SAR):</th>
   <th class="col3"> مبلغ ضريبة القيمة المضافة (ريال سعودي) <br>VAT Amount (SAR):</th>
   <th class="col6"> نسبة الضريبة (%) <br>VAT %:</th>
   <th class="col3"> الإجمالي بدون ضريبة (ريال سعودي) <br>Total Excl. VAT (SAR):</th>
   <th class="col3"> سعر الوحدة (ريال سعودي)<br>Unit Rate (SAR):</th>
   <th class="col6"> الكمية <br>Qty:</th>
   <th class="col3"> وحدة <br>Unit:</th>
   <th class="colgoods" style="text-align: center;"> طبيعة السلع/الخدمات <br>Nature of Goods/Services:(Description)</th>
   
   <th class="col6">#</th>
 </tr>
</thead>
<tbody>
 ${products.map((product, index) => `
   <tr>
     <td class="col1">${product.totalInclVAT}</td>
     <td class="col1">${product.vatAmount}</td>
     <td class="col1">${product.vatRate}</td>
     <td class="col1">${product.totalExclVAT}</td>
     <td class="col1">${product.unitRate}</td>
     <td class="col1">${product.quantity}</td>
     <td class="col1">UNIT</td>
     <td class="col1" id="translatedNatureOfGoods">${product.natureOfGoods} /${translatedGoods}
    </td>
     
     <td class="col1">${index + 1}</td>
   </tr>
 `).join('')}
</tbody>
</table>

<!-- Summary Section -->
<table class="Summary">
<tr>
 <td class="col4">Total Excl. VAT (SAR):</td>
 <td class="col8">${calculateTotalExclVAT(products)}</td>
 <td class="col5">الإجمالي بدون ضريبة (ريال سعودي)</td>
 <td colspan="4" rowspan="2" class="text-section">
           
             ${example.productDescription}

         </td>
</tr>
<tr>
 <td class="col4">VAT Amount (SAR):</td>
 <td class="col8">${calculateTotalVAT(products)}</td>
 <td class="col5">مبلغ ضريبة القيمة المضافة (ريال سعودي)</td>
</tr>
<tr>
 <td class="col4">Total Incl. VAT (SAR):</td>
 <td class="col8">${calculateTotalInclVAT(products)}</td>
 <td class="col5">الإجمالي شامل الضريبة (ريال سعودي)</td>
 <td colspan="3" rowspan="2" class="signature-section">
         ${translatedDescription}
         </td>
</tr>
<tr>
 <td class="col4">Balance Due (SAR):</td>
 <td class="col8">${calculateBalanceDue(products)}</td>
 <td class="col5">الرصيد المستحق (ر.س)</td>
</tr>
</table>
 

 <table>
     <tr>
         <th colspan="4" class="new">Bank Details / التفاصيل المصرفية</th>
         <th colspan="2" class="new">Prepared By / معد الفاتورة</th>
         <th colspan="2" class="new">Received By / مستلم الفاتورة</th>
         <td rowspan="6" class="qr-section">
             <!-- QR Code Frame -->
             <div id="qrcode"></div>
             
         </td>
     </tr>
     <tr>
         <td colspan="2"><strong>ACCOUNT NAME</strong> / اسم الحساب</td>
         <td colspan="2" class="collast" >MOHAMMED SAUD BIN DHAFER AL YAMI CONT. EST.<br>مؤسسة محمد سعود بن ظافر اليامي للمقاولات</td>
         <td  rowspan="5" class="signature-section">
             <p style="font-size: 12px;">Signature with stamp</p><span class="arabic">التوقيع مع الختم</span>
         </td>
         <td colspan="3" rowspan="5" class="signature-section">
             <p style="font-size: 12px;">Signature with stamp</p><span class="arabic">التوقيع مع الختم</span>
         </td>
     </tr>
     <tr>
         <td colspan="2"><strong>BANK</strong> / بنك</td>
         <td colspan="2" class="collast">Al Rajhi bank</td>
     </tr>
     <tr>
         <td colspan="2"><strong>ACCOUNT</strong> / الحساب</td>
         <td colspan="2" class="collast">146000010006086540879</td>
     </tr>
     <tr>
         <td colspan="2"><strong>IBAN</strong> / ايبان</td>
         <td colspan="2" class="collast">SA0380000146608016540879</td>
         
     </tr>
     <tr>
         <td colspan="2"><strong></strong> </td>
         <td colspan="2" class="collast"></td>
     </tr>
 </table>

</body>

           <!-- QR Code Section -->
           <div class="qr-code">
             <!--h3>Scan QR Code for Bill Details</h3>
             <div id="qrcode"></div-->
           </div>
         </div>
        <script>
           const qrcode = document.getElementById('qrcode');
           const qrCodeData =  '${getBillDetailsForQRCode()}';
             
           const qrCodeImage = 'https://api.qrserver.com/v1/create-qr-code/?data=' + encodeURIComponent(qrCodeData) + '&size=150x150';
           const imgElement = document.createElement('img');
           imgElement.src = qrCodeImage;
           qrcode.appendChild(imgElement);
         </script>
       </body>
     </html>
 

 
   `);
   printWindow.document.close();
   printWindow.print();
  
      };

      
    
  return (
    
    
    <Paper
  style={{
    padding: 20,
    margin: '20px auto',
    maxWidth: 1000,
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  }}
>
<img
  src={logo}
  alt="Logo"
  style={{
    display: 'block',
    margin: '0 auto',
    maxWidth: '100%',  // Ensures the logo does not exceed the container width
    height: 'auto',    // Maintains the aspect ratio of the image
  }}
/>

  {/* Main Heading */}
  <Typography variant="h4" gutterBottom align="center" style={{ marginBottom: 30, color: '#125B9A' }}>
    Invoice Generator
  </Typography>

  {/* Our Details Section */}
  <Typography variant="h6" gutterBottom align="center" style={{ marginBottom: 20, fontWeight: 'bold', color: '#0B2F9F' }}>
    Our Details
  </Typography>

  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField
        label="Name"
        value="Mohammed Saud Bin Dhafer Al Yami Cont.Est."
        InputProps={{ readOnly: true }}
        fullWidth
      />
    </Grid>
    {/* Company details */}
    <Grid item xs={4}>
      <TextField label="Post Box No" value="35514" InputProps={{ readOnly: true }} fullWidth />
    </Grid>
    <Grid item xs={4}>
      <TextField label="Addl. No" value="8944" InputProps={{ readOnly: true }} fullWidth />
    </Grid>
    <Grid item xs={4}>
      <TextField label="Building No" value="HNAB4999" InputProps={{ readOnly: true }} fullWidth />
    </Grid>

    <Grid item xs={4}>
      <TextField label="City" value="Makkah mukarramah street" InputProps={{ readOnly: true }} fullWidth />
    </Grid>
    <Grid item xs={4}>
      <TextField label="District" value="Safath jubail" InputProps={{ readOnly: true }} fullWidth />
    </Grid>
    <Grid item xs={4}>
      <TextField label="Country" value="Kingdom of Saudi Arabia" InputProps={{ readOnly: true }} fullWidth />
    </Grid>

    <Grid item xs={4}>
      <TextField label="Postal Code" value="11564" InputProps={{ readOnly: true }} fullWidth />
    </Grid>
    <Grid item xs={4}>
      <TextField label="VAT Number" value="311404508500003" InputProps={{ readOnly: true }} fullWidth />
    </Grid>
    <Grid item xs={4}>
      <TextField label="CRN" value="2055145790" InputProps={{ readOnly: true }} fullWidth />
    </Grid>
  </Grid>

  {/* Client Details Section */}
  <Typography variant="h6" gutterBottom align="center" style={{ marginBottom: 20, fontWeight: 'bold', color: '#0B2F9F' }}>
    Client Details
  </Typography>

  <Grid container spacing={2}>
    {/* Client Details Inputs */}
        <Grid item xs={4}>
          <TextField
            label="Name"
            name="clientName"
            value={clientDetails.clientName}
            onChange={(e) => handleInputChange(e, 'client')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Address"
            name="clientAddress"
            value={clientDetails.clientAddress}
            onChange={(e) => handleInputChange(e, 'client')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="District"
            name="clientDistrict"
            value={clientDetails.clientDistrict}
            onChange={(e) => handleInputChange(e, 'client')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Postal Code"
            name="clientPostalCode"
            value={clientDetails.clientPostalCode}
            onChange={(e) => handleInputChange(e, 'client')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Country"
            name="clientCountry"
            value={clientDetails.clientCountry}
            onChange={(e) => handleInputChange(e, 'client')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Addl. No"
            name="clientAddNo"
            value={clientDetails.clientAddNo}
            onChange={(e) => handleInputChange(e, 'client')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="VAT Number"
            name="clientVatNumber"
            value={clientDetails.clientVatNumber}
            onChange={(e) => handleInputChange(e, 'client')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="CRN"
            name="clientCRN"
            value={clientDetails.clientCRN}
            onChange={(e) => handleInputChange(e, 'client')}
            fullWidth
          />
        </Grid>
      </Grid>

      {/* Invoice Details Section */}
      <Typography variant="h6" gutterBottom align="center" style={{ marginBottom: 20,fontWeight: 'bold' ,color:'#0B2F9F' }}>
        Invoice Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="Invoice Date"
            type="date"
            name="invoiceDate"
            value={invoiceDetails.invoiceDate}
            onChange={(e) => handleInputChange(e, 'invoice')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Supply Date"
            type="date"
            name="supplyDate"
            value={invoiceDetails.supplyDate}
            onChange={(e) => handleInputChange(e, 'invoice')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Contract / PO No"
            name="contractNo"
            value={invoiceDetails.contractNo}
            onChange={(e) => handleInputChange(e, 'invoice')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Invoice Period"
            name="invoicePeriod"
            value={invoiceDetails.invoicePeriod}
            onChange={(e) => handleInputChange(e, 'invoice')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Project / Reference No"
            name="projectRefNo"
            value={invoiceDetails.projectRefNo}
            onChange={(e) => handleInputChange(e, 'invoice')}
            fullWidth
          />
        </Grid>
        
      </Grid>

       {/* Products Section */}
       <Typography variant="h6" gutterBottom align="center" style={{ marginBottom: 20, fontWeight: 'bold', color: '#0B2F9F' }}>
    Products
  </Typography>

  {products.map((product, index) => (
    <div key={index} style={{ marginBottom: '20px' }}>
      <h4>Product {index + 1}</h4>
      <Grid container spacing={2}>
         
         {/* Nature of Goods Input */}
         <Grid item xs={4}>
           <TextField
             type="text"
             name="natureOfGoods"
             value={product.natureOfGoods}
             onChange={(e) => handleProductChange(e, index)}
             placeholder="Nature of Goods"
             fullWidth
           />
         </Grid>
   
         {/* Unit Rate Input */}
         <Grid item xs={4}>
           <TextField
             type="number"
             name="unitRate"
             value={product.unitRate}
             onChange={(e) => handleProductChange(e, index)}
             placeholder="Unit Rate"
             fullWidth
           />
         </Grid>
   
         {/* Quantity Input */}
         <Grid item xs={4}>
           <TextField
             type="number"
             name="quantity"
             value={product.quantity}
             onChange={(e) => handleProductChange(e, index)}
             placeholder="Quantity"
             fullWidth
           />
         </Grid>
   
         {/* VAT Percentage Input */}
         <Grid item xs={4}>
           <TextField
             type="number"
             name="vatRate"
             value={product.vatRate}
             onChange={(e) => handleProductChange(e, index)}
             placeholder="VAT Rate"
             fullWidth
           />
         </Grid>
   
         {/* Total Amount In Words Input */}
         <Grid item xs={8}> {/* Adjusting the grid size to make the field longer */}
           <TextField
             label="Final Amount In Words"
             type="text"
             name="productDescription"
             value={product.productDescription}
             onChange={(e) => handleProductChange(e, index)}
             placeholder="Enter the Final Amount"
             fullWidth
           />
         </Grid>
   
          </Grid>
          {/* Display Calculated Totals */}
           <div>
        <p>Total Excl. VAT: {product.totalExclVAT} SAR</p>
        <p>VAT Amount: {product.vatAmount} SAR</p>
        <p>Total Incl. VAT: {product.totalInclVAT} SAR</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ backgroundColor: '#ff4081' }}
          onClick={() => removeProductRow(index)}
        >
          <DeleteIcon />
        </Button>
      </div>
    </div>
  ))}

  {/* Add product row button */}
  <Button variant="contained" color="primary" onClick={addProductRow} style={{ margin: '20px 0', backgroundColor: '#3f51b5' }}>
    Add Product
  </Button>

      {/* Totals for all products */}
      <div style={{ marginTop: 20 }}>
        <Typography variant="h6" style={{fontWeight: 'bold',color:'#0B2F9F'}}>
          Totals
        </Typography>
        <p>Total Excl. VAT: {calculateTotalExclVAT()} SAR</p>
        <p>Total VAT: {calculateTotalVAT()} SAR</p>
        <p>Total Incl. VAT: {calculateTotalInclVAT()} SAR</p>
        <p>Balance Due: {calculateBalanceDue()} SAR</p>
        
      </div>

      {/* Print Invoice button */}
      <div style={{ textAlign: 'center', marginTop: 30 }}>
  {/* First row with large Translate button */}
  <div>
  <Button
  variant="contained"
  color="primary"
  style={{ backgroundColor: '#ff5722', padding: '15px 30px', fontSize: '18px', marginBottom: '20px' }}
  onClick={() => {
    translateText(example.productDescription);
    translateText(example.natureOfGoods);
  }}
  disabled={isLoading}
>
  {isLoading ? 'Translating...' : 'Translate'}
</Button>
  </div>
  
  {/* Second row with Print and Download buttons */}
  <div>
  <Button
      variant="contained"
      color="primary"
      style={{ backgroundColor: '#4caf50', padding: '10px 20px', fontSize: '16px',width:'150px', marginRight: '10px' }}
      onClick={printInvoice}
      disabled={isLoading}
      >
        {isLoading ? 'Translating...' : 'Translate'}
      Print 
    </Button>
  
    <Button
  variant="contained"
  color="primary"
  style={{ backgroundColor: '#007bff', padding: '10px 20px', fontSize: '16px',width:'150px' }}
  onClick={() => window.open('https://wa.me/919486042369?text=Hello, I need help with...', '_blank')}
>
  Help
</Button>
  </div>
</div>
    </Paper>
  );
};

export default InvoiceGenerator;



